<template>
  <div v-show="show">
    <div
      class="inset-0 z-[1000]"
      :class="{
        'bg-gray-900 bg-opacity-50 dark:bg-opacity-80': shadow,
        'backdrop-blur-sm': blur,
        absolute: scoped,
        fixed: !scoped,
      }"/>
    <Transition name="fade">
      <div
        v-if="show"
        ref="modalRef"
        tabindex="0"
        class="overflow-y-auto overflow-x-hidden top-0 right-0 left-0 z-[1001] w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
        :class="{
          absolute: scoped,
          fixed: !scoped,
        }"
        @keyup.esc="closeWithEsc"
        @click.self="clickOutside">
        <div
          class="relative p-4 w-full h-full md:h-auto md:-top-35"
          :class="`${modalSizeClasses[size]}`">
          <!-- Modal content -->
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div
              v-if="showHeader"
              class="rounded-t flex justify-between items-center"
              :class="
                $slots.header
                  ? `border-b border-gray-200 dark:border-gray-600 ${props.headerPadding}`
                  : `absolute left-2 ${props.headerPadding}`
              ">
              <slot name="header" />
              <button
                v-if="showHeader"
                aria-label="close"
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mr-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                @click="closeModal">
                <slot name="close-icon">
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
                  </svg>
                </slot>
              </button>
            </div>
            <!-- Modal body -->
            <div :class="`${props.bodyPadding}`">
              <slot name="body" />
            </div>
            <!-- Modal footer -->
            <div
              v-if="$slots.footer"
              :class="`${props.padding} rounded-b ${props.borders}`">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script lang="ts" setup>
import type { ModalSize } from './types';
import { onMounted, ref, watchEffect } from 'vue';
import type { Ref } from 'vue';
import { useScrollLock } from '@vueuse/core';

interface ModalProps {
  size?: ModalSize;
  escapable?: boolean;
  persistent?: boolean;
  showHeader?: boolean;
  shadow?: boolean;
  show?: boolean;
  scoped?: boolean;
  blur?: boolean;
  borders?: string;
  padding?: string;
  headerPadding?: string;
  bodyPadding?: string;
}
const props = withDefaults(defineProps<ModalProps>(), {
  size: '2xl',
  escapable: true,
  persistent: false,
  showHeader: true,
  shadow: true,
  show: true,
  scoped: false,
  borders: 'border-t dark:border-gray-600',
  padding: 'p-6',
  headerPadding: 'p-4',
  bodyPadding: 'p-10',
});
const emit = defineEmits(['close', 'click:outside']);
const isLocked = useScrollLock(document.body);
const modalSizeClasses = {
  xs: 'max-w-xs max-h-screen',
  sm: 'max-w-sm max-h-screen',
  md: 'max-w-md max-h-screen',
  lg: 'max-w-lg max-h-screen',
  xl: 'max-w-xl max-h-screen',
  '2xl': 'max-w-2xl max-h-screen',
  '3xl': 'max-w-3xl max-h-screen',
  '4xl': 'max-w-4xl max-h-screen',
  '5xl': 'max-w-5xl max-h-screen',
  '6xl': 'max-w-6xl max-h-screen',
  '7xl': 'max-w-7xl max-h-screen',
};

watchEffect(() => {
  isLocked.value = props.show;
});

function closeModal() {
  emit('close');
}
function clickOutside() {
  if (!props.persistent) {
    emit('click:outside');
    closeModal();
  }
}

function closeWithEsc() {
  if (props.escapable && !props.persistent) closeModal();
}
const modalRef: Ref<HTMLElement | null> = ref(null);
onMounted(() => {
  if (modalRef.value) {
    modalRef.value.focus();
  }
});
</script>
